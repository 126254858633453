<template>
    <div>
        <!-- Header -->
        <div class="header  py-7 py-lg-8 pt-lg-9">
            <b-container>
                <div class="header-body text-center mb-3">
                    <h1 class="text-white text-weight-bold">MOMENS 2.0</h1>
                </div>
            </b-container>

        </div>
        <!-- Page content -->
        <b-container class="mt--8 pb-5">
            <b-row class="justify-content-center">
                <b-col lg="5" md="7">
                    <b-card no-body class="bg-black-transparant border-0 mb-0">

                        <b-card-body class="px-lg-5 py-lg-5">
                            <div v-if='isSuccess==true' role="alert" aria-live="polite" aria-atomic="true" class="alert alert-success alert-dismissible">
                                <!---->
                                <span class="alert-text"><strong>Success!</strong>Reset password successfull!</span>
                                <button @click="closeAlert" type="button" data-dismiss="alert" aria-label="Close" class="close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div v-if='isError==true' role="alert" aria-live="polite" aria-atomic="true" class="alert alert-danger alert-dismissible">
                                <!---->
                                <span class="alert-text"><strong>Error!</strong> {{errorMsg}} </span>
                                <button @click="closeAlert"  type="button" data-dismiss="alert" aria-label="Close" class="close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="text-center text-muted mb-4">
                                <!-- <small></small> -->
                                <h3 class="text-white text-weight-bold">Reset Password</h3>
                            </div>
                            <validation-observer v-slot="{handleSubmit}" ref="formValidator">


                                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                                    <base-input alternative
                                                class="mb-3"
                                                name="Password"
                                                :rules="{required: true, min: 6}"
                                                prepend-icon="ni ni-lock-circle-open"
                                                type="password"
                                                placeholder="Password"
                                                v-model="record.password">
                                    </base-input>

                                    <base-input alternative
                                                class="mb-3"
                                                name="password_confirmation"
                                                :rules="{required: true, min: 6}"
                                                prepend-icon="ni ni-lock-circle-open"
                                                type="password"
                                                placeholder="Password Confirmation"
                                                v-model="record.password_confirmation">
                                    </base-input>
                                    <!--
                                     <router-link to="" class="text-primary "><em>Forgot password?</em></router-link>
                                     -->

                                    <div class="text-center">
                                        <base-button type="primary" native-type="submit" class="my-4">Reset Password</base-button><br>
                                        <router-link to="/login" class="text-primary "><em>Back To Login ?</em></router-link>
                                    </div>

                                </b-form>
                            </validation-observer>
                        </b-card-body>
                    </b-card>
                    <!-- <b-row class="mt-3">
                      <b-col cols="6">
                        <router-link to="/dashboard" class="text-light"><small>Forgot password?</small></router-link>
                      </b-col>
                      <b-col cols="6" class="text-right">
                        <router-link to="/register" class="text-light"><small>Create new account</small></router-link>
                      </b-col>
                    </b-row> -->
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script src="{{ asset('js/app.js?version='.date("ymdhis").'') }}"></script>
<script>
import {LoginURL, clientId, clientSecret, apiDomain, getHeader, homeLink} from '@/assets/js/config.js'
import axios from 'axios'
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";

export default {
    mixins: [ErrorFunctionMixin],
    data() {
        return {
            record: {},
            item: {},
            token: this.$route.params.token,
            blackImgLogo : null,
            userLoginImg : null,
            isSuccess : false,
            isError : false,
            successMsg : null,
            errorMsg : null,
            url :apiDomain,
        };
    },
    methods: {
        getValue(resource, key) {
            return getValue(resource, key);
        },
        getUserDetail() {
            axios.get(apiDomain+'reset-password/'+this.token)
                .then ( response => {
                    this.item.id = response.data.data.id;
                })
                .catch ( error => {
                    this.errorFunction(error, "Reset Password")
                } );
        },
        onSubmit() {
            this.isSuccess = false;
            this.isError = false;
            const postData = {
                password : this.record.password,
                password_confirmation : this.record.password_confirmation,
                id : this.item.id,
                token : this.token
            }

            axios.post(apiDomain+'reset_password', postData)
                .then ( response => {
                    this.isSuccess = true;
                    this.isSuccMsg = '';
                })
                .catch ( error => {
                    let errorMessage = error.response.data.message;
                    Object.keys(errorMessage).forEach(key => {
                        this.errorMsg = errorMessage[key]; // Use key to access value in error message
                    });
                    this.isError = true;
                } );

        },
        closeAlert() {
            this.isSuccess = false;
            this.isError = false;
            this.errorMsg = null;
        }
    },
    beforeMount() {
        document.body.classList.add('bg-default-moment');
        this.getUserDetail();
    }
};
</script>
<style scoped>
    .bg-black-transparant {
        background-color:#3E3E3E99;
    }
    .bg-card-dashboard {
        background-color: #23454A;
    }
    .bg-default-dashboard {
        background-color: #082A2F;
        /* background-size: cover; */
        /* position:fixed; */
        padding:0;
        margin:0;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
    }
    .form-control {
        background-color: #2C545A;
        border-color: #2C545A;
        color: white;
    &:focus {
         background-color: #2C545A;
         border-color: #2C545A;
         color: white;
     }
    &::placeholder {
         color: white;
     }
    }
    .form-control {
    &:focus {
    &::placeholder {
         color: white;
     }
    }
    }
    .input-group-text {
        background-color: #2C545A;;
        color: white;
    &:focus {
         background-color: #2C545A;;
         border-color: #1D4147;
         color: white;
     }
    }
    .focused {
    .input-group-text {
        background-color: #2C545A;
    }
    }
    .navbar-search-dark .input-group {
        background-color: #23454A;
        color: black;
        border-color: rgba(255, 255, 255, 0.6);
    }
    .navbar-search .input-group {
        border-radius: 7px;
    }
    .el-input__inner, .el-select-dropdown__item.is-disabled:hover {
        background-color: #23454A;
    }
    .line-custom {
        background-color: #356168; margin-top: 15px; margin-bottom: 15px;
    }
</style>
